import React, { useCallback, useEffect, useState } from 'react';
import ReactFlow, { Background, Controls, MiniMap, Panel, addEdge, applyEdgeChanges, applyNodeChanges } from 'reactflow';

import 'reactflow/dist/style.css';
import Tutorial from './Tutorial';
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormLabel,
    Input,
    Select,
    VStack, DrawerOverlay, DrawerCloseButton, DrawerBody, DrawerFooter, DrawerHeader, ButtonGroup, Drawer, DrawerContent, Flex, HStack, Icon, Text, useDisclosure
} from '@chakra-ui/react';
import { MdAdd, MdApps, MdInfoOutline, MdOpenInNew } from 'react-icons/md';
import { nodes as initialNodes } from '../data/nodes.js';
import initialEdges from '../data/edges.js';
import Products from './accordionItems/Products';
import Assets from './accordionItems/Assets';

export default function FlowEditor({ onDismiss, showTutorial }) {
    const [nodes, setNodes] = useState(initialNodes);
    const [edges, setEdges] = useState(initialEdges);
    const [selectedNode, setSelectedNode] = useState(null);


    const {
        isOpen,
        onOpen,
        onClose,
    } = useDisclosure();

    const placeholderNodeId = 'placeholder';

    useEffect(() => {
        console.log('selectedNode changed', selectedNode);
        if (selectedNode) {
            onOpen();
        } else {
            onClose();
        }
    }, [selectedNode]);

    const onPaneClick = () => {
        setSelectedNode(null);
    };


    const isValidConnection = (connection) => {
        const sourceNode = nodes.find(node => node.id === connection.source);
        const targetNode = nodes.find(node => node.id === connection.target);

        // Data sources can connect to databases, but not vice versa
        return sourceNode.type === 'input' && targetNode.type !== 'input';
    };


    const onNodesChange = useCallback(
        (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
        [setNodes]
    );
    const onEdgesChange = useCallback(
        (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        [setEdges]
    );
    const onConnect = useCallback(
        (connection) => setEdges((eds) => addEdge(connection, eds)),
        [setEdges]
    );


    const handleCreateDatabase = () => {
        // Logic to create the database based on selected options
        console.log("Creating new database with configured properties");
        onClose(); // Close the drawer after creation
    };


    const handleTriggerClick = (triggerName) => {
        const x = Math.random() * 250;
        const y = Math.random() * 250;

        if (isNaN(x) || isNaN(y)) {
            console.error('x or y is not a number');
            return;
        }

        const newNode = {
            id: triggerName + Math.random().toString(36).substring(2, 15), // Generate a unique ID
            type: 'default',
            data: { label: triggerName },
            position: { x: x, y: y },
        };

        const verticalOffset = 100; // This is the distance between the new node and the placeholder node along the Y-axis

        const placeholderNode = {
            id: placeholderNodeId + newNode.id, // Generate a unique ID
            type: 'default',
            data: {
                label: (
                    <select
                        defaultValue='Choose an option'
                    >
                        <option
                            value='Choose an option'
                        >Choose an option
                        </option>
                        {/* {triggerOptions[triggerName].map((option) => (
                            <option
                                key={option}
                                value={option}
                            >
                                {option}
                            </option>
                        ))} */}
                        <option
                            key='connect_app'
                            value='Connect App'>
                            Connect App
                        </option>
                    </select>
                ),
            },
            position: { x: x, y: y + verticalOffset }, // Placing it directly below the new node
        };

        setNodes((nodes) => [...nodes, newNode, placeholderNode]);
        onClose(); // Close the drawer

        // Add an edge that connects the new node to the placeholder node
        const newEdge = { id: 'e' + newNode.id + '-' + placeholderNode.id, source: newNode.id, target: placeholderNode.id };
        setEdges((edges) => addEdge(newEdge, edges));
    };

    const onNodeClick = (event, node) => {
        console.log('Node clicked:', node.id);
        if (node.type !== 'input') {
            setSelectedNode(node);
        }
        // Add your logic here for what should happen when a node is clicked
    };


    const connectionLineStyle = { stroke: '#6699ff' };
    const edgeOptions = {
        animated: true,
        style: {
            stroke: '#6699ff',
        },
    };
    const nodeColor = (node) => {
        switch (node.type) {
            case 'input':
                return '#6EA6FF';
            case 'output':
                return '#1B2559';
            default:
                return '#1B2559';
        }
    };

    const textColorSecondary = "gray.400";

    return (
        <Flex width="100%" height="90vh">
            <ReactFlow
                nodes={nodes}
                edges={edges}
                // onNodeMouseEnter={onNodeMouseEnter}
                // onNodeMouseLeave={onNodeMouseLeave}
                onPaneClick={onPaneClick}
                onNodeClick={onNodeClick}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                isValidConnection={isValidConnection}
                defaultEdgeOptions={edgeOptions}
                connectionLineStyle={connectionLineStyle}
                fitView
            >
                <Background color="#ccc" variant='cross' />
                <Panel>
                    {showTutorial ? <Tutorial onDismiss={onDismiss} /> :
                        <Icon as={MdInfoOutline} color={textColorSecondary} fontSize='24px' />}
                </Panel>
                {/* <Panel position='top-right'>
                    <Button ref={flowEditorDrawerBtnRef} colorScheme="brand" onClick={onOpenDrawer}>
                        <MdOutlineKeyboardDoubleArrowLeft h='50px' w='50px' />
                    </Button>
                </Panel> */}
                {showTutorial ? null
                    : <Panel position='top-right'>
                        <ButtonGroup>
                            {/* <Button fontWeight={500} fontSize='sm' color={textColorSecondary} m='0'>
                                <HStack>
                                    <Text textColor={textColorSecondary}>Show Logs</Text>
                                    <MdOpenInNew w='20px' h='20px' color={textColorSecondary} />
                                </HStack>
                            </Button> */}
                            <Button
                                borderRadius="md"
                                fontWeight={700} fontSize='sm' color='white' m='0' variant='brand'
                                onClick={onOpen}
                            >
                                <HStack>
                                    <Icon as={MdAdd} />
                                    <Text textColor='white'>New Automation</Text>
                                </HStack>
                            </Button>
                            {/* <Button fontWeight={700} fontSize='sm' color='white' m='0' variant='brand'
                            >
                                <HStack>
                                    <Icon as={MdApps} />
                                </HStack>
                            </Button> */}
                        </ButtonGroup>
                    </Panel>
                }
                <Controls />
                <MiniMap nodeColor={nodeColor} nodeStrokeWidth={3} zoomable pannable />
            </ReactFlow>

            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={onClose}
                size='md'
            >
                <DrawerOverlay />

                <DrawerContent
                    bg='gray.200'
                >
                    <DrawerCloseButton />
                    <DrawerHeader borderBottomWidth="1px" bg="white" boxShadow="sm">
                        {selectedNode ? 'Edit Database' : 'Configure New Database'}
                    </DrawerHeader>

                    <DrawerBody>
                        <VStack spacing={6} align="stretch" mt={4}>
                            <Accordion defaultIndex={[0]} allowMultiple>
                                <AccordionItem>
                                    <AccordionButton bg="white" _hover={{ bg: "gray.100" }}>
                                        <Box flex="1" textAlign="left" fontWeight="medium">
                                            Select Data Source
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                    <AccordionPanel bg="white" p={4}>
                                        <VStack align="stretch" spacing={3}>
                                            <Checkbox colorScheme="blue">Google Analytics</Checkbox>
                                            <Checkbox colorScheme="blue">Facebook Ads</Checkbox>
                                            <Checkbox colorScheme="blue">Shopify</Checkbox>
                                        </VStack>
                                    </AccordionPanel>
                                </AccordionItem>
                                <AccordionItem mt={4}>
                                    <AccordionButton bg="white" _hover={{ bg: "gray.100" }}>
                                        <Box flex="1" textAlign="left" fontWeight="medium">
                                            Configure Custom Properties
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                    <AccordionPanel bg="white" p={4}>
                                        <VStack align="stretch" spacing={4}>
                                            <FormControl>
                                                <FormLabel fontWeight="medium">Event Type</FormLabel>
                                                <Select placeholder="Select event type" bg="white">
                                                    <option value="click">Click Events</option>
                                                    <option value="pageview">Page Views</option>
                                                    <option value="conversion">Conversions</option>
                                                </Select>
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel fontWeight="medium">Time Range</FormLabel>
                                                <Select placeholder="Select time range" bg="white">
                                                    <option value="7">Last 7 days</option>
                                                    <option value="30">Last 30 days</option>
                                                    <option value="90">Last 90 days</option>
                                                </Select>
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel fontWeight="medium">Minimum Threshold</FormLabel>
                                                <Input type="number" placeholder="Enter minimum value" bg="white" />
                                            </FormControl>
                                        </VStack>
                                    </AccordionPanel>
                                </AccordionItem>
                            </Accordion>
                        </VStack>
                    </DrawerBody>

                    <DrawerFooter borderTopWidth="1px" bg="white">
                        {selectedNode ? (
                            <Button colorScheme="brand" borderRadius="md" onClick={handleCreateDatabase} size="lg" width="full">
                                Save Changes
                            </Button>
                        ) : (
                            <Button colorScheme="brand" borderRadius="md" onClick={handleCreateDatabase} size="lg" width="full">
                                Create Database
                            </Button>
                        )}
                    </DrawerFooter>
                </DrawerContent>

            </Drawer>
        </Flex>
    );
}