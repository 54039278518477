/*eslint-disable*/
import { Portal, Box, useDisclosure, Icon, layout } from '@chakra-ui/react';
import AiAssistantDrawer from 'components/drawers/AiAssistantDrawer';
// Layout components
import Navbar from 'components/navbar/NavbarAdmin.js';
import Sidebar from 'components/sidebar/Sidebar.js';
import { SidebarContext } from 'contexts/SidebarContext';
import React, { useState } from 'react';
import { BsImageFill } from 'react-icons/bs';
import { FaChartBar, FaDatabase, FaFacebook, FaGoogle, FaShopify } from 'react-icons/fa';
import { IoMdDocument } from 'react-icons/io';
import { IoBarChart, IoPricetag } from 'react-icons/io5';
import { MdAdd, MdBarChart, MdDataUsage, MdInsights, MdAutoAwesome, MdPeople, MdCampaign, MdBuildCircle, MdCalendarMonth, MdExtension, MdFolder, MdFolderOpen, MdImage, MdOutlineShoppingCart, MdPerson, MdSettings, MdStackedBarChart } from 'react-icons/md';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Bounce, ToastContainer } from 'react-toastify';
import { logData } from 'util/Logger';
import { log } from 'util/Logger';
import AppMarketPlace from 'views/addcomponent';
import AddPage from 'views/addpage';
import DigitalAssetsPage from 'views/assets';
import ChannelPerformance from 'views/channelPerformance';
import ChurnPrediction from 'views/churnPrediction';
import CustomerLifetimeValue from 'views/customerLifetimeValue';
import CustomerSegmentationPage from 'views/customers';
import Default from 'views/dashboard';
import FacebookAdsView from 'views/dataSources/facebook/FacebookAdsView';
import GoogleAdsView from 'views/dataSources/google/GoogleAdsView';
import GoogleAnalytics4View from 'views/dataSources/google/GoogleAnalytics4View';
import ShopifyView from 'views/dataSources/shopify/ShopifyView';
import EngagementAnalytics from 'views/engagementAnalytics';
import FeedbackSentiment from 'views/feedbackSentiment';
import LoyaltyProgramAnalytics from 'views/loyaltyProgramAnalytics';
import ProductOverview from 'views/overviewProduct';
import ProductAffinity from 'views/productAffinity';
import Settings from 'views/settings';
import WorkflowsPage from 'views/workflows';

export const DEFAULT_ROUTES = [
	{
		name: "Summary",
		layout: "/admin",
		path: "/summary",
		icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
		component: Default,
	},
	{
		name: "Customer Segments",
		layout: "/admin",
		path: "/segments",
		icon: <Icon as={MdPeople} width='20px' height='20px' color='inherit' />,
		component: CustomerSegmentationPage,
	},

	{
		name: "Analytics",
		layout: "/admin",
		path: "/analytics",
		icon: <Icon as={MdInsights} width='20px' height='20px' color='inherit' />,

		isCategory: true,
		items: [
			{
				name: "Churn Prediction",
				layout: "/admin",
				path: "/churn-prediction",
				icon: <Icon as={MdDataUsage} width='20px' height='20px' color='inherit' />,
				component: ChurnPrediction,
			},
			{
				name: "Customer Lifetime Value",
				layout: "/admin",
				path: "/clv",
				icon: <Icon as={MdStackedBarChart} width='20px' height='20px' color='inherit' />,
				component: CustomerLifetimeValue,
			},
			{
				name: "Loyalty Program",
				layout: "/admin",
				path: "/loyalty-program",
				icon: <Icon as={IoPricetag} width='20px' height='20px' color='inherit' />,
				component: LoyaltyProgramAnalytics,
			},
			{
				name: "Engagement",
				layout: "/admin",
				path: "/engagement",
				icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
				component: EngagementAnalytics,
			},
			{
				name: "Product Affinity",
				layout: "/admin",
				path: "/product-affinity",
				icon: <Icon as={MdOutlineShoppingCart} width='20px' height='20px' color='inherit' />,
				component: ProductAffinity,
			},
			{
				name: "Feedback",
				layout: "/admin",
				path: "/feedback-sentiment",
				icon: <Icon as={MdCampaign} width='20px' height='20px' color='inherit' />,
				component: FeedbackSentiment,
			},
			{
				name: "Channel Performance",
				layout: "/admin",
				path: "/channel-performance",
				icon: <Icon as={FaChartBar} width='20px' height='20px' color='inherit' />,
				component: ChannelPerformance,
			},
		]
	},
	{
		name: "Automation",
		layout: "/admin",
		icon: <Icon as={MdAutoAwesome} width='20px' height='20px' color='inherit' />,
		path: "/automation",
		component: WorkflowsPage,
		comingSoon: true,
	},
	{
		name: "Settings",
		layout: "/admin",
		icon: <Icon as={MdSettings} width='20px' height='20px' color='inherit' />,
		path: "/settings",
		component: Settings,
	}
];

export default function Dashboard(props) {
	const { ...rest } = props;
	// states and functions
	const [fixed] = useState(false);
	const [toggleSidebar, setToggleSidebar] = useState(false);

	// todo cj COMPONENTS: fetch active components here...

	// functions for changing the states from components
	const getRoute = () => {
		return window.location.pathname !== '/admin/full-screen-maps';
	};
	const getActiveRoute = (routes) => {
		const defaultRoute = 'Default Brand Text';

		for (let i = 0; i < routes.length; i++) {
			const route = routes[i];

			if (route.collapse || route.isCategory) {
				const activeRoute = getActiveRoute(route.items);
				if (activeRoute !== defaultRoute) {
					return activeRoute;
				}
			} else if (window.location.href.includes(`${route.layout}${route.path}`)) {
				if (route.category) {
					const capitalizedCategory = `${route.category.charAt(0).toUpperCase()}${route.category.slice(1)}`;
					return `${capitalizedCategory} > ${route.name}`;
				}
				return route.name;
			}
		}

		return defaultRoute;
	};

	const getActiveRouteCustomTitle = (routes) => {
		const defaultRoute = 'Default Brand Text';

		for (let i = 0; i < routes.length; i++) {
			const route = routes[i];

			if (route.collapse || route.isCategory) {
				const activeRoute = getActiveRouteCustomTitle(route.items);
				if (activeRoute !== defaultRoute) {
					return activeRoute;
				}
			} else if (window.location.href.includes(`${route.layout}${route.path}`)) {
				logData('defaultRoute 2', route);
				return route.customTitle || route.name;
			}
		}

		logData('defaultRoute', defaultRoute);
		return defaultRoute;
	};

	const getActiveNavbar = (routes) => {
		let activeNavbar = false;
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveNavbar = getActiveNavbar(routes[i].items);
				if (collapseActiveNavbar !== activeNavbar) {
					return collapseActiveNavbar;
				}
			} else if (routes[i].category) {
				let categoryActiveNavbar = getActiveNavbar(routes[i].items);
				if (categoryActiveNavbar !== activeNavbar) {
					return categoryActiveNavbar;
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					return routes[i].secondary;
				}
			}
		}
		return activeNavbar;
	};
	const getActiveNavbarText = (routes) => {
		let activeNavbar = false;
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveNavbar = getActiveNavbarText(routes[i].items);
				if (collapseActiveNavbar !== activeNavbar) {
					return collapseActiveNavbar;
				}
			} else if (routes[i].category) {
				let categoryActiveNavbar = getActiveNavbarText(routes[i].items);
				if (categoryActiveNavbar !== activeNavbar) {
					return categoryActiveNavbar;
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					return routes[i].messageNavbar;
				}
			}
		}
		return activeNavbar;
	};
	const getRoutes = () => {
		let routes = [];
		DEFAULT_ROUTES.forEach((prop, key) => {
			if (prop.isCategory) {
				prop.items.forEach((prop, i) => {
					routes.push(
						<Route
							path={prop.layout + prop.path}
							component={prop.component}
							key={`${key}-${i}`}
						/>
					);
				});
			} else {
				routes.push(
					<Route
						path={prop.layout + prop.path}
						component={prop.component}
						key={key}
					/>
				);
			}
		});
		console.log('routes', routes);
		return routes;
	};
	document.documentElement.dir = 'ltr';
	const { onOpen } = useDisclosure();
	document.documentElement.dir = 'ltr';

	const location = useLocation();
	const bgColor = location.pathname == '/admin/components'
		? 'linear(to-r, #1E2F62, #3F1F5D)'
		: null;

	const btnRef = React.useRef();
	const {
		isOpen: showAiAssistant,
		onOpen: onOpenAiAssistant,
		onClose: onCloseAiAssistant,
	} = useDisclosure();

	const toggleAiAssistant = () => {
		onOpenAiAssistant();
		log('showAiAssistant', showAiAssistant);
	}

	return (
		<Box>
			<Box>
				<SidebarContext.Provider
					value={{
						toggleSidebar,
						setToggleSidebar
					}}>
					<Sidebar routes={DEFAULT_ROUTES} display='none' {...rest} />
					<Box
						float='right'
						minHeight='100vh'
						height='100%'
						overflowX='auto'
						overflowY='auto'
						position='relative'
						maxHeight='100%'
						background={'gray.200'}
						w={{ base: '100%', xl: 'calc( 100% - 290px )' }}
						maxWidth={{ base: '100%', xl: 'calc( 100% - 290px )' }}
						transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
						transitionDuration='.2s, .2s, .35s'
						transitionProperty='top, bottom, width'
						transitionTimingFunction='linear, linear, ease'
					>
						<Portal>
							<Box>
								<Navbar
									onOpen={onOpen}
									logoText={'Merchant Dashboard PRO'}
									brandText={getActiveRoute(DEFAULT_ROUTES)}
									secondary={getActiveNavbar(DEFAULT_ROUTES)}
									message={getActiveNavbarText(DEFAULT_ROUTES)}
									fixed={fixed}
									customTitle={getActiveRouteCustomTitle(DEFAULT_ROUTES)}
									{...rest}
								/>
							</Box>
						</Portal>

						{getRoute() ? (
							<Box mx='auto' px={{ base: '20px', md: '30px' }} pe='0px' minH='100vh' pt='50px'>
								<Switch>
									{getRoutes(DEFAULT_ROUTES)}
									<Redirect from='/' to='/admin/dashboard' />
								</Switch>
							</Box>
						) : null}

						{/* <Box>
							<Footer />
						</Box> */}
					</Box>
				</SidebarContext.Provider>
			</Box>
			{/* <AiAssistant btnRef={btnRef} toggleAiAssistant={toggleAiAssistant} /> */}
			<AiAssistantDrawer isOpen={showAiAssistant} onClose={onCloseAiAssistant} btnRef={btnRef} onOpen={onOpenAiAssistant} />
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
				transition={Bounce}
			/>
			{/* Same as */}
			<ToastContainer />
		</Box>
	);
}
