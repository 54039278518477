// chakra imports
import { Avatar, Box, Button, Flex, Input, Stack, Text, Textarea, useColorModeValue } from "@chakra-ui/react";
//   Custom components
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";
import React from "react";
import { SidebarCard } from "./SidebarCard";

import avatar4 from "assets/img/avatars/avatar4.png";
import Card from "components/card/Card";

// FUNCTIONS

function SidebarContent(props) {
  const { routes } = props;
  const textColor = useColorModeValue('navy.700', 'white');

  // SIDEBAR
  return (
    <Flex direction="column" height="100%" pt="25px" px="16px" borderRadius="30px" background={'#ffffff'} >
      <Brand />
      <Stack direction="column" mb="auto" mt="8px">
        <Box
        // ps={'20px'}
        // pe={{ md: '16px', '2xl': '1px' }}
        // ms={'-16px'}
        >
          <Links routes={routes} />
          {/* <Card
            boxShadow="base"
            borderRadius="sm"
            bg="yellow.200"
            mt="20px"
          >
            <Text
              fontSize="lg"
              fontWeight="bold"
              color={textColor}
            >
              Thank you for being an early access user!
            </Text>
            <Text
              fontSize="md"
              color={textColor}>
              <br />
          Some features are still in progress. We're working hard to bring you the best experience possible.
            </Text>
            <Textarea
              placeholder="Please provide any feedback or suggestions to help  us improve the platform..."
              variant="solid"
              size="md"
              minH={"200px"}
            />
            <Button
              mt="20px"
              colorScheme="blue"
              variant="solid"
              size="sm"
            >
              Submit
            </Button>
          </Card> */}
        </Box>
      </Stack>


    </Flex>
  );
}

export default SidebarContent;
