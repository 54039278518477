/*eslint-disable*/
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useContext, useEffect, useState } from "react";
// Chakra imports
import { Box, MenuList, MenuItem, FormLabel, FormControl, CircularProgress, Flex, Heading, Icon, SimpleGrid, Text, IconButton, useColorModeValue, useDisclosure, Alert, AlertTitle, AlertDescription, HStack, VStack, Divider, Menu, Badge, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Select, ModalFooter, Input, MenuButton } from "@chakra-ui/react";
// Custom components
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { WidgetFactoryContext } from "contexts/WidgetFactoryContext";
import { AuthContext } from "contexts/AuthContext";
import { log } from "util/Logger";
import OrderDetailsComponent from "components/orderDetails";
import QuickActions from "components/orderDetails/components/QuickActions";
import DrawerFactory from "components/drawers/DrawerFactory";
import { logData } from "util/Logger";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import { IoMdAlert, IoMdCart, IoMdPeople, IoMdTime, IoMdTrendingUp, IoMdInformationCircle, IoIosApps, IoIosConstruct } from "react-icons/io";
import { MdAdd, MdMoreHoriz, MdEdit, MdShare, MdDelete, MdContentCopy, MdAddShoppingCart, MdAreaChart, MdBarChart, MdBubbleChart, MdChevronLeft, MdChevronRight, MdFilterAlt, MdGridOn, MdInsertChart, MdOutlineShoppingCart, MdPieChart, MdScatterPlot, MdStars, MdTableChart, MdTimeline, MdWaterfallChart } from "react-icons/md";
import Card from "components/card/Card";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaFileExport, FaMagic } from "react-icons/fa";
import { getInsights } from "api/analytics/getInsights";
import { set } from "date-fns";
import ConnectedAppsTableOrders from "views/orderList/components/ConnectedAppsTableOrders";
import DashboardUpsell from "./components/DashboardUpsell";
import { httpsCallable } from "firebase/functions";
import { functions } from "firebaseApp";
import Skeleton from "components/dashboard/Skeleton";
import { CgImport } from "react-icons/cg";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { Area, AreaChart, Bar, BarChart, CartesianGrid, Legend, Line, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis, Cell, FunnelChart, Funnel, LabelList } from "recharts";
import EditWidgetModal from "./components/EditModal";
import { v4 as uuidv4 } from 'uuid';
import AddWidgetModal from "./components/editModals/AddWidgetModal";
import NewDashboardModal from "./components/editModals/NewDashboardModal";
import { MdCampaign, MdTrendingUp, MdSettings, MdAttachMoney } from "react-icons/md";
import { Table, Thead, Tbody, Tr, Th, Td, TableCaption } from "@chakra-ui/react";
import BubbleChart from "components/charts/BubbleChart";
import LineChart from "components/charts/LineChart";



const retentionRateData = [
  {
    name: "Retention Rate",
    data: [85, 87, 86, 88, 90, 89],
  },
];

export default function Default() {
  const [dashboards, setDashboards] = useState({});
  const [selectedDashboard, setSelectedDashboard] = useState("Default");

  const [isAddWidgetModalOpen, setIsAddWidgetModalOpen] = useState(false);

  const [selectedSegment, setSelectedSegment] = useState("All");
  const [selectedChannel, setSelectedChannel] = useState("All");


  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const brandColor = useColorModeValue("brand.500", "white");


  const getMiniStats = () => [
    {
      title: "Customer Retention Rate",
      amount: "85%",
      icon: <IconBox w='56px' h='56px' bg={boxBg} icon={<Icon w='32px' h='32px' as={IoMdPeople} color={brandColor} />} />,
    },
    {
      title: "Active Campaigns",
      amount: "12",
      icon: <IconBox w='56px' h='56px' bg={boxBg} icon={<Icon w='32px' h='32px' as={MdCampaign} color={brandColor} />} />,
    },
    {
      title: "Avg. Customer Lifetime Value",
      amount: "$1,200",
      icon: <IconBox w='56px' h='56px' bg={boxBg} icon={<Icon w='32px' h='32px' as={MdTrendingUp} color={brandColor} />} />,
    },
    {
      title: "Revenue from Retained Customers",
      amount: "$45,000",
      icon: <IconBox w='56px' h='56px' bg={boxBg} icon={<Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />} />,
    },
  ];

  // Sample data for visualizations
  const segmentationData = [
    { name: 'Champions', value: 400 },
    { name: 'At-Risk', value: 300 },
    { name: 'New Customers', value: 300 },
  ];

  const adPerformanceData = [
    { platform: 'Facebook Ads', ctr: '2.5%', conversionRate: '3.2%', roas: '2.1' },
    { platform: 'Google Ads', ctr: '3.1%', conversionRate: '2.8%', roas: '1.9' },
  ];

  const salesFunnelData = [
    { value: 1000, name: 'Visitors', fill: '#26A0FC' },
    { value: 600, name: 'Leads', fill: '#83a6ed' },
    { value: 400, name: 'Prospects', fill: '#8dd1e1' },
    { value: 200, name: 'Customers', fill: '#82ca9d' },
  ];

  const recentActivityData = [
    { action: 'New segment "High-Value Customers" created', timestamp: '2 hours ago' },
    { action: 'Summer Sale campaign launched', timestamp: '1 day ago' },
    { action: 'Customer feedback received: "Great service!"', timestamp: '3 days ago' },
  ];


  return (
    <Flex flexDirection="column" height="calc(100vh - 80px)" overflowY="inherit">

      <Box pt={{ base: "130px", md: "80px", xl: "80px" }} height="calc(100vh - 8px)" overflowY="inherit">
        <Flex direction="row" width="100%" justify={"space-between"}>
          <SimpleGrid columns={{ base: 1, md: 2, xl: 4 }} gap='20px' mb='20px'>
            {getMiniStats().map((stat, index) => (
              <MiniStatistics
                key={index}
                startContent={stat.icon}
                name={stat.title}
                value={stat.amount}
              />
            ))}
          </SimpleGrid>


        </Flex>

        <SimpleGrid columns={{ base: 1, md: 2 }} gap='20px' mb='20px'>


          <Card>
            <Heading size="md" mb="4">
              Retention Rate Over Time
            </Heading>
            <Select
              mb="4"
              value={selectedSegment}
              onChange={(e) => setSelectedSegment(e.target.value)}
            >
              <option value="All">All Segments</option>
              <option value="High Value">High Value</option>
              <option value="Medium Value">Medium Value</option>
              <option value="Low Value">Low Value</option>
            </Select>
            <Select
              mb="4"
              value={selectedChannel}
              onChange={(e) => setSelectedChannel(e.target.value)}
            >
              <option value="All">All Channels</option>
              <option value="Online">Online</option>
              <option value="In-store">In-store</option>
              <option value="Mobile">Mobile</option>
            </Select>
            <LineChart
              chartData={retentionRateData}
              chartOptions={{
                
                xaxis: {
                  categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
                },
                yaxis: {
                  title: {
                    text: "Retention Rate (%)",
                  },
                },
              }}
            />
          </Card>


          <Card>
            <Heading
              as="h2"
              fontSize="xl"
              fontWeight="bold"
              mb={4}
            >Ad Performance Summary</Heading>
            <Box>
              <Table variant="simple">
                <TableCaption>Ad Performance across platforms</TableCaption>
                <Thead>
                  <Tr>
                    <Th>Platform</Th>
                    <Th>CTR</Th>
                    <Th>Conversion Rate</Th>
                    <Th>ROAS</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {adPerformanceData.map((row, index) => (
                    <Tr key={index}>
                      <Td>{row.platform}</Td>
                      <Td>{row.ctr}</Td>
                      <Td>{row.conversionRate}</Td>
                      <Td>{row.roas}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          </Card>

          <Card>
            <Heading
              as="h2"
              fontSize="xl"
              fontWeight="bold"
              mb={4}
            >Sales Funnel</Heading>
            <Box>
              <ResponsiveContainer width="100%" height={300}>
                <FunnelChart>
                  <Tooltip />
                  <Funnel dataKey="value" data={salesFunnelData}>
                    <LabelList position="right" fill="#000" stroke="none" dataKey="name" />
                  </Funnel>
                </FunnelChart>
              </ResponsiveContainer>
            </Box>
          </Card>

          <Card>
            <Heading
              as="h2"
              fontSize="xl"
              fontWeight="bold"
              mb={4}
            >Recent Activity</Heading>
            <Box>
              <VStack align="stretch" spacing={4}>
                {recentActivityData.map((activity, index) => (
                  <Box key={index} p={3} bg="gray.100" borderRadius="md">
                    <Text fontWeight="bold">{activity.action}</Text>
                    <Text fontSize="sm" color="gray.500">{activity.timestamp}</Text>
                  </Box>
                ))}
              </VStack>
            </Box>
          </Card>
        </SimpleGrid>
      </Box>
    </Flex>
  );
}
